var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "img-container w-100 cover-image",
        style: "background-image: url(" + _vm.image + ")"
      },
      [_vm._m(0)]
    ),
    _c("div", { staticClass: "container my-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("RequestServiceForm", {
              attrs: {
                services: _vm.services,
                service: _vm.service,
                city: _vm.city
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overlay" }, [
      _c("h4", [_vm._v("Richiedi il servizio di assistenza")]),
      _c("div", [
        _c("p", { staticClass: "md:hidden" }, [
          _vm._v("Segui la procedura che ti verrà proposta")
        ]),
        _c("p", [
          _vm._v(
            "Attendi la conferma da parte della nostra agenzia territoriale"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }