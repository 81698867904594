<template>
    <div>
      <div
        class="img-container w-100 cover-image"
        :style="'background-image: url(' + image + ')'"
      >
        <div class="overlay">
          <h4>Richiedi il servizio di assistenza</h4>
          <div>
            <p class="md:hidden">Segui la procedura che ti verrà proposta</p>
            <p>Attendi la conferma da parte della nostra agenzia territoriale</p>
          </div>
        </div>
      </div>
      <div class="container my-4">
        <div class="row">
          <div class="col-md-12">
            <RequestServiceFormAffilate
              :services="services"
              :service="service"
              :city="city"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import i18n from "@/i18n";
  import { mapActions, mapGetters } from "vuex";
  import RequestServiceFormAffilate from "@/components/forms/services/RequestServiceFormAffiliate";
  import { test } from "@/services/service.service"; // Import the test method
  
  export default {
      name: "RequestServiceAffiliate",
      metaInfo: {
        titleTemplate: "%s" + i18n.t("menu.services"),
        meta: [{ name: "description", content: i18n.t("menu.services") }],
      },
      props: {
        service: {
          type: Object,
          default: null,
        },
        city: {
          type: Object,
          default: null,
        },
      },
      components: { RequestServiceFormAffilate },
      data() {
        return {
          image: require("@/assets/images/service_request.jpg"),
        };
      },
      computed: {
        ...mapGetters({
          loading: "services/loading",
          services: "services/services",
        }),
      },
      methods: {
        ...mapActions("services", ["test"]),
        loadServices(userId) {
          // Call the test method instead of getAll
          test(userId).then(
            (services) => {
              // Process the fetched services
              this.$store.commit("services/getAllSuccess", services);
            },
            (error) => {
              // Handle error if necessary
              console.error("Error fetching services:", error);
            }
          );
        },
      },
      mounted() {
        const loggedUserId = this.$loggedUser().id; // Get the logged user's ID
        console.log("Logged User ID:", loggedUserId);
        if (this.services || this.services.length) {
          // Load services for the logged user
          console.log("Loading services for the logged user");
          this.loadServices(loggedUserId);
        }
      },
    };
  </script>
  
  <style scoped>
  h1,
  h2 {
    margin: 0;
  }
  
  h3 {
    margin-top: 0.5rem;
  }
  
  .img-container {
    position: relative;
  }
  
  .img-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgb(0, 170, 170);
    opacity: 0;
    transition: opacity 500ms ease-in-out; */
  }
  
  /* .img-container:hover .overlay {
    opacity: 1;
  } */
  .overlay h4 {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 50%;
    /*  transform: translate(-50%, -50%); */
  }
  
  .cover-image {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  @media (max-width: 576px) {
    .overlay h4 {
      font-size: 1rem;
      top: 5%;
    }
  
    .overlay p {
      font-size: 0.5rem;
    }
  }
  
  .overlay div {
    position: absolute;
    bottom: 10%;
    left: 10%;
    right: 50%;
  }
  </style>
  